import { useState, useEffect, useRef, useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import moment from 'moment';
import { Button, Badge } from 'react-bootstrap';
import EditVMModal from './EditVMModal';
import EditMessageModal from './EditMessageModal';
import _ from 'lodash'
var QRCode = require('qrcode.react');

const giftrDomain = window.location.hostname === 'seller.giftr.my' ? 'https://giftr.my' : 'https://giftr.sg'

const cellProps = [
  { style: { display: 'flex', alignItems: 'center' } }
]

const LineItemRow = ({ orderId, user, style, fulfillmentStatus, cancelledAt, displaySection, handleOnClickAdjustment, noteAttributes, onPrintMessage, selectedIds, row, onClickSaveAttribute, isMobile, currency, ...rest }) => {
  const [showDetail, setShowDetail] = useState(false);
  const [showEditVMModal, setShowEditVMModal] = useState(false);
  const [showEditMessageModal, setShowEditMessageModal] = useState(false);
  const [clickToggle, setClickToggle] = useState(false);

  const trRef = useRef();

  useEffect(() => {
    if (row.values && row.values.expand_details && row.values.expand_details.isExpanded) {
      setShowDetail(true)
    }
  }, [row])

  useEffect(() => {
    if (showDetail && trRef.current && clickToggle) {
      trRef.current.scrollIntoView()
    }
  }, [showDetail, trRef, clickToggle])

  const handleSaveAttribute = (name, value) => {
    onClickSaveAttribute([{ name, value }]);
  }

  const { lineItem: item } = row.values;

  let { vmLink, hasVM } = useMemo(() => {
    let hasVM = _.find(item.properties, prop => {
      return prop.name === 'Audio/Video Message'
    })
    let vmLink = hasVM ? hasVM.value : ''
    const editedVM = _.find(noteAttributes, note => _.includes(note.name, `virtualmessage_${item.id}`))

    return editedVM ?
      { vmLink: editedVM.value, hasVM: true }
      :
      { vmLink, hasVM }

  }, [item, noteAttributes])

  let { message, has_vm_or_message } = useMemo(() => {
    let message = _.find(item.properties, prop => {
      return prop.name === 'Message on Card'
    })
    message = message ? message.value : ''
    const editedMessage = _.find(noteAttributes, note => _.includes(note.name, `messageoncard_${item.id}`))
    if(editedMessage){
      message = editedMessage.value
    }

    const has_vm_or_message = (vmLink && vmLink !== 'N/A') || message

    return { message, has_vm_or_message }
  }, [item, noteAttributes])

  let shipment = useMemo(() => {
    let shipment = _.find(noteAttributes, note => _.includes(note.name, `shipment_${item.id}`))

    return shipment ? shipment.value.split('_') : ''
  }, [noteAttributes])

  const onClickShowDetail = () => {
    setClickToggle(true)
    setShowDetail(!showDetail)
  }

  return (
    <>
      <tr className="line-item" ref={trRef} {...rest} {...row.getRowProps()}>
        { row.cells.map(cell => {
          if (cell.column.id === 'mobile') {
            const { title, vendor, delivery, ship_by, message, quantity, price, total_price, handle } = cell.value
            return (
              <td {...cell.getCellProps(cellProps)} className="d-grid">
                <div className="d-flex align-items-center gap-3">
                  { title !== 'Add-ons' &&
                  <div className="line-item-image" style={{maxWidth: '60px'}}>
                    <img src={item.image} alt={title}/>
                    <span className="badge badge-quantity">{item.quantity}</span>
                  </div>
                  }
                  { title === 'Add-ons' && 'Add-ons' }
                  { title !== 'Add-ons' && handle &&
                    <a target="_blank" href={`${giftrDomain}/products/${handle}`} className="text-decoration-none" style={{ color: 'inherit' }}>{title}</a>
                  }
                  { title !== 'Add-ons' && !handle &&
                    <span style={{ color: 'inherit' }}>{title}</span>

                  }
                </div>
                <p className="small mb-1 text-secondary">
                  { user && (user.role === 'admin' || user.role === 'staff') &&
                    <>Vendor: {vendor}<br/></>
                  }
                  { delivery &&
                    <>Delivery: {delivery}<br/></>
                  }
                  { ship_by &&
                    <>Ship by: {ship_by}<br/></>
                  }
                  { message &&
                    <>Message: <Button className="print-vm-button copy-to-clipboard py-1" variant="warning" onClick={() => onPrintMessage(item.id)}>Print</Button><br/></>
                  }
                  <>Quantity: {quantity}<br/></>
                  <>Price: {price}<br/></>
                  <>Total price: {total_price}<br/></>
                </p>
                { row.values.expand_details.showToggle &&
                <div className="text-center rounded-3 text-white cursor-pointer" style={{background: 'rgba(0,0,0,0.1)'}} onClick={onClickShowDetail}>
                    { showDetail ?
                      <FontAwesomeIcon icon={solid('chevron-up')} style={{ color: '#5a5a5a' }}/>
                      :
                      <FontAwesomeIcon icon={solid('chevron-down')} style={{ color: '#5a5a5a' }}/>
                    }
                  </div>
                }
              </td>
            )
          }

          if (cell.column.id === 'selection_and_expand') {
            return (
              <td {...cell.getCellProps(cellProps)} className="gap-2">
                {cell.render('Cell')}
                { row.values.expand_details.showToggle && !isMobile &&
                  <>
                    { showDetail ?
                      <FontAwesomeIcon size="lg" icon={solid('circle-chevron-up')} style={{ color: '#80d7cf' }} onClick={onClickShowDetail} className="cursor-pointer"/>
                      :
                      <FontAwesomeIcon size="lg" icon={solid('circle-chevron-down')} style={{ color: '#80d7cf' }} onClick={onClickShowDetail} className="cursor-pointer"/>
                    }
                  </>
                }
              </td>
            )
          }

          if (cell.column.id === 'message') {
            return (
              cell.value ?
                <td {...cell.getCellProps(cellProps)}>
                  <Button className="print-vm-button copy-to-clipboard py-1" variant="warning" onClick={() => onPrintMessage(item.id)}>Print</Button>
                </td>
                :
                <td {...cell.getCellProps(cellProps)}></td>
            )
          }

          if (cell.column.id === 'expand_details') {
            return
          }

          return (
            <td {...cell.getCellProps(cellProps)}>
              {cell.render('Cell')}
            </td>
          )
        })}
      </tr>
      { showDetail &&
      <tr className="fadeIn">
        <td className="pt-3">
          <div className="overflow-auto">
          <table className="table">
            <tbody>
              <tr key={item.id}>
                <td width="70">
                  {
                    item.image &&
                      <div className="line-item-image">
                        <img src={item.image} alt={item.title}/>
                        <span className="badge badge-quantity">{item.quantity}</span>
                      </div>
                  }
                </td>
                <td className="line-item-detail" colSpan="2">
                  <div>
                    { item.handle &&
                      <a target="_blank" href={`${giftrDomain}/products/${item.handle}`}><strong>{item.title} - {item.quantity} Unit(s)</strong></a>
                    }
                    {!item.handle &&
                      <span className="item-title"><strong>{item.title} - {item.quantity} Unit(s)</strong></span>
                    }
                  </div>
                  {
                    item.sku ?
                      <div className="mb-2"><b>SKU:</b> {item.sku}</div>
                      :
                    null
                  }
                  { item.variant_title &&
                    <div className="d-grid mb-2">
                      <div><b>Variant:</b> {item.variant_title}</div>
                      { (_.toLower(item.variant_title).includes("pick-up") || _.toLower(item.variant_title).includes("pickup") || _.toLower(item.variant_title).includes("pick up") || _.toLower(item.variant_title).includes("self")) &&
                        <span className="order-detail-item-desc" style={{color: '#d15d5d'}}><i>Reminder from Giftr: *Please contact customer for self pick-up arrangement.</i></span>
                      }
                    </div>
                  }
                  {
                    item.est_delivery ?
                      <div className="d-grid mb-2">
                        <label>Est. Delivery</label>
                        <span>{item.est_delivery}</span>
                      </div>
                      :
                      null
                  }
                  {
                    item.ship_by ?
                      <div className="d-grid mb-2">
                        <label>Ship By</label>
                        { item.ship_by && moment(item.ship_by, 'DD/MM/YYYY').isSameOrBefore(moment(), 'day') && displaySection !== 'fulfilled' && _.lowerCase(fulfillmentStatus) !== 'fulfilled' && !cancelledAt ? <span style={{color: '#d15d5d'}}>{item.ship_by}</span> : <span>{item.ship_by}</span>}
                      </div>
                      :
                      null
                  }
                  {
                    item.grams ?
                      <div className="d-grid mb-2">
                        <label>Weight</label>
                        <span>{item.grams} grams</span>
                      </div>
                      :
                      null
                  }
                  {
                    item.properties && !item.name.match(/(- add ons)/i) &&

                      item.properties.map((property, j) => {
                        if (property.name === 'aoid' || property.name === '_bundleId' || property.name === '_main' || property.name === 'Fulfilled by') {
                          return
                        }

                        if(property.name === 'Delivery'){
                          const editedDeliveryDate = _.find(noteAttributes, note => _.includes(note.name, `deliverydate_${item.id}`))

                          return (
                            <div className="d-grid" key={j}>
                              <label>{`Delivery${editedDeliveryDate ? ' (Edited)' : ''}`}</label>
                              <p className="order-detail-item-desc">{editedDeliveryDate ? editedDeliveryDate.value : property.value}</p>
                            </div>
                          )
                        }

                        const edited = _.find(noteAttributes, note => _.includes(note.name, `customisation_${item.id}_${property.name}`))
                        const value = (edited && edited.value) ? edited.value : property.value

                        if(hasVM){
                          return (
                            <div className="d-grid" key={j}>
                              <div className="d-flex flex-wrap">
                                {
                                  property.name !== 'Audio/Video Message' && !_.startsWith(property.name, '_') &&
                                    <label className='me-2'>{property.name}</label>
                                }
                                { edited && edited.value &&
                                  <span className="order-detail-item-desc" style={{ color: '#d15d5d' }}><i>*This customisation has been changed</i></span>
                                }
                                {
                                  property.name === 'Audio/Video Message' &&
                                    <>
                                      {
                                        has_vm_or_message &&
                                          <Button className="print-vm-button copy-to-clipboard py-1" variant="warning" onClick={() => onPrintMessage(item.id)}>Click to Print Message (Include QR Code)</Button>
                                      }
                                      {
                                        user && (user.role === 'admin' || user.role === 'staff') &&
                                          <>
                                            <Button className="print-vm-button copy-to-clipboard py-1" variant="secondary" onClick={() => setShowEditMessageModal(true)}>Edit Message on Card</Button>
                                            <Button className="print-vm-button copy-to-clipboard py-1" variant="secondary" onClick={() => setShowEditVMModal(true)}>Edit Virtual Message</Button>
                                          </>
                                      }
                                    </>
                                }
                              </div>
                              <div className="order-detail-item-desc mb-3">
                                {
                                  (property.name !== 'Message on Card' && property.name !== 'Audio/Video Message') && !_.startsWith(property.name, '_') &&
                                    value
                                }
                                { property.name === 'Audio/Video Message' &&
                                  <>
                                    {
                                      has_vm_or_message ?
                                        <div className="preview-message-content mt-1" style={{ border: '1px solid #81d8d0', padding: '15px' }}>
                                          <p className="order-detail-item-desc" style={{ fontSize: '1.2em' }}>{message}</p>
                                          <div style={{ marginTop: "30px", textAlign: "center" }}>
                                            <span style={{ fontWeight: 400 }}>Sender has left you a special message</span>
                                          </div>
                                          <div style={{ marginTop: "15px", textAlign: "center" }}>
                                            <a href={`${giftrDomain}/pages/virtual-message?shortcode=${orderId}-${item.id}`} target="_blank" style={{ width: "100px" }}>
                                              <div className="wrapper mb-3" width={128} height={128}>
                                                <QRCode
                                                  value={`${giftrDomain}/pages/virtual-message?shortcode=${orderId}-${item.id}`}
                                                  renderAs={"svg"}
                                                  size={100}
                                                  bgColor={"#ffffff"}
                                                  fgColor={"#000000"}
                                                  level={"L"}
                                                  includeMargin={false}
                                                />
                                              </div>
                                            </a>
                                          </div>
                                          <div style={{ textAlign: "center" }}>
                                            <span style={{ fontWeight: 400 }}>Scan here 👆</span>
                                          </div>
                                        </div>
                                        :
                                        <span className="order-detail-item-desc" style={{color: '#d15d5d'}}><i>Reminder from Giftr: *No message left by sender. Please proceed to send an empty message card.</i></span>
                                    }
                                  </>
                                }
                              </div>
                            </div>
                          )
                        }

                        return (

                          <div className="d-grid" key={j}>
                            <div>
                              <label>{property.name}</label>
                              {
                                property.name === 'Message on Card' && value !== "" &&
                                  <Button className="copy-to-clipboard ms-2 py-1" variant="secondary" data-clipboard-text={value}>COPY</Button>
                              }
                              {
                                property.name === 'Message on Card' && user && (user.role === 'admin' || user.role === 'staff') &&
                                  <Button className="print-vm-button copy-to-clipboard py-1" variant="secondary" onClick={() => setShowEditMessageModal(true)}>Edit Message on Card</Button>
                              }
                              { edited && edited.value &&
                                <span className="order-detail-item-desc" style={{ color: '#d15d5d' }}><i>*This customisation has been changed</i></span>
                              }
                            </div>
                            {
                              property.name === 'Message on Card' ?
                                <p className="order-detail-item-desc">{message === "" ? <span style={{color: '#d15d5d'}}><i>Reminder from Giftr: *No message left by sender. Please proceed to send an empty message card.</i></span> : message}</p>
                                :
                                <p className="order-detail-item-desc">{value}</p>
                            }

                          </div>)
                      })
                  }
                  {
                    (item.adjustment_item_price || item.adjustment_shipping) &&
                      <>
                        <hr/>
                        <div className="d-grid gap-2 mb-2">
                          {
                            item.adjustment_item_price &&
                              <div className="d-grid gap-1">
                                <label>Item Price Adjustment</label>
                                <span>{currency} {item.adjustment_item_price.toFixed(2)}</span>
                              </div>
                          }
                          {
                            item.adjustment_shipping &&
                              <div className="d-grid gap-1">
                                <label>Shipping Adjustment</label>
                                <span>{currency} {item.adjustment_shipping.toFixed(2)}</span>
                              </div>
                          }
                          {
                            item.adjustment_remarks &&
                              <div className="d-grid gap-1">
                                <label>Adjustment Remarks</label>
                                <span>{item.adjustment_remarks}</span>
                              </div>
                          }
                        </div>
                      </>
                  }
                </td>
                <td colSpan="1" className="text-end" style={{verticalAlign: "middle"}}>
                  {
                    shipment &&
                      <div className="mb-5 d-grid gap-1" style={{wordBreak: 'break-word'}}>
                        <div>
                          <Badge bg="info" className="text-uppercase">Shipment</Badge>
                        </div>
                        {shipment[0]}
                        <div>
                          {item.shipment_status}
                        </div>
                      </div>
                  }
                  <div className="d-inline-flex flex-column">
                    {item.ready_to_ship && <div><Badge bg="success" className="text-uppercase mb-2">Accepted</Badge><br/></div>}
                    <span className="white-space-no-wrap"><span className="d-print-none">{currency} {item.price} </span>x {item.quantity > 1 ? (<span className="highlight">{item.quantity}</span>) : item.quantity } Unit(s)</span><br/><span className="d-print-none white-space-no-wrap">= {currency} {(item.price * item.quantity).toFixed(2)}</span><br/>
                    { user && (user.role === 'admin' || user.role === 'staff') && 
                      <Button className="d-print-none btn-br-6 mt-2" variant="secondary" onClick={() => {handleOnClickAdjustment(item)}}>Adjustment</Button> 
                    }
                  </div>
                </td>
                {
                  showEditMessageModal &&
                    <EditMessageModal message={message} onClose={() => setShowEditMessageModal(false)} onClickSave={(val) => handleSaveAttribute(`messageoncard_${item.id}`, val)}/>
                }
                {
                  showEditVMModal &&
                    <EditVMModal link={hasVM && vmLink} onClose={() => setShowEditVMModal(false)} onClickSave={(val) => handleSaveAttribute(`virtualmessage_${item.id}`, val)}/>
                }
              </tr>
            </tbody>
          </table>
          </div>
        </td>
      </tr>
      }
    </>
  )
}

export default LineItemRow;
