import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, Well, Button, Alert, Col, Modal, Anchor, Row, Form, Container } from 'react-bootstrap';
import Loader from '../components/Loader';
import api from '../helpers/apiClient';
import config from '../config'
import * as merchantsActionCreators from '../redux/actions/merchants';
import { SingleDatePicker } from 'react-dates';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, regular, brands } from '@fortawesome/fontawesome-svg-core/import.macro'
import Dropzone from 'react-dropzone'
import * as Promise from "bluebird";
import _ from "lodash"

const currency = window.location.hostname === 'seller.giftr.my' ? 'RM' : 'S$'

class CreateManualOrder extends Component{
  constructor(props) {
    super(props)

    let form_data = {
      item_price: 0,
      shipping_fee: 0,
      markup_type: 'fixed',
      notify_customer: false
    }

    let deliveryDate, paymentDueDate, 
    itemPrice = 0, markupTotal = 0, shippingFee = 0, commission = 0, commissionRate = 0, sst = 0,
    vendorSelected = '', commissionChargeable = false, sstChargeable = false, partialPaid = 0,
    delivery_type = 'nationwide';

    if(props.item){
      let item = props.item
      form_data = {
        ...form_data,
        ...item
      }

      if(item.delivery_date){
        form_data.delivery_date = moment(item.delivery_date)
        deliveryDate = moment(item.delivery_date)
        delivery_type = 'on-demand'
      }

      if(item.ship_by_date){
        form_data.delivery_date = moment(item.ship_by_date)
        deliveryDate = moment(item.ship_by_date)
      }

      if(item.payment_due_date){
        form_data.payment_due_date = moment(item.payment_due_date)
        paymentDueDate = moment(item.payment_due_date)
      }

      itemPrice = item.item_price
      markupTotal = item.markup_total
      shippingFee = item.shipping_fee
      commission = parseInt(item.commission)
      commissionRate = parseInt(item.commission_rate)
      commissionChargeable = commission > 0 && commissionRate > 0
      sstChargeable = item.sst > 0
      sst = item.sst
      vendorSelected = item.vendor
      partialPaid = item.partial_paid_amount
    }

    this.state = {
      mode: props.mode ? props.mode : 'create',
      orderFile: '',
      commissionChargeable,
      sstChargeable,
      vendorSelected,
      commissionRate,
      partialPaid,
      itemPrice,
      shippingFee,
      commission,
      sst,
      form_data,
      file_url: '',
      orderFile: undefined,
      invoiceFile: undefined,
      invoice_uploading: false,
      sales_receipt_uploading: false,
      error: undefined,
      delivery_type,
      markupType: 'fixed',
      markupTotal,
      submitting: false,
      deliveryDate,
      paymentDueDate
    }
  }

  componentDidMount() {
    const { fetchMerchants, mode } = this.props;
    const { merchants, commissionChargeable, sstChargeable } = this.state;
    
    if(!merchants || !merchants.items){
      fetchMerchants();
    }

    if(mode === 'edit'){

      if(this.commissionRef){
        this.commissionRef.checked = commissionChargeable
      }

      if(this.sstRef){
        this.sstRef.checked = sstChargeable
      }

      this.updateCalculation()
    }
  }

  handleOnClose = () => {
    this.props.onClose();
  }

  getVoucherSKUs = async e => {

    const {form_data, giftcard_skus} = this.state
    const node = e.target

    this.setState({form_data: {
      ...form_data,
      [node.name]: node.checked
    }})

    if(!giftcard_skus){
      let res = await api(`/giftcards/skus`, {method: 'GET'})

      this.setState({giftcard_skus: res})
    }
  }

  getVendor = async e => {
    this.updateForm(e)

    const {form_data} = this.state
    const vendorSelected = e.target.value
    let res
    if(vendorSelected){
      res = await api(`/merchants/name/${vendorSelected}`, {method: 'GET'})
    } else {
      this.commissionRef.checked = false
      this.sstRef.checked = false
    }
    let commissionRate = (res && res.commission_rate) ? res.commission_rate : 0

    this.setState({
      vendorSelected,
      commissionRate,
      form_data: {
        ...form_data,
        commission_rate: commissionRate,
        vendor: vendorSelected
      },
      ...(vendorSelected === "" && {
        commissionChargeable: false,
        sstChargeable: false
      })
    }, () => {
      this.updateCalculation();
    })
  }

  handlePartialPaid = e => {
    this.updateForm(e)
    this.setState({partialPaid: parseFloat(e.target.value)})
  }

  handleItemPrice = e => {
    this.updateForm(e)
    this.setState({itemPrice: parseFloat(e.target.value)}, () => {
      this.updateCalculation();
    })
  }

  handleMarkupType = e => {
    this.updateForm(e)
    this.setState({markupType: e.target.value}, () => {
      this.updateMarkup();
    })
  }

  handleMarkup = e => {
    this.updateForm(e)
    this.setState({ markup: e.target.value }, () => {
      this.updateMarkup()
    })
  }

  updateMarkup = () => {
    const { itemPrice, markupType, markup } = this.state

    if (!markup) {
      return
    }

    let markupTotal = 0
    if (markupType === 'percentage') {
      if (itemPrice) {
        markupTotal = itemPrice * parseFloat(markup) / 100
      }
    } else if (markupType === 'fixed') {
      markupTotal = parseFloat(markup)
    }

    this.setState({ markupTotal })
    this.updateForm({target: {name: 'markup_total', value: markupTotal}})
  }

  handleShippingFee = e => {
    this.updateForm(e)
    this.setState({shippingFee: parseFloat(e.target.value)})
  }

  handleCommission = value => {
    const checked = value.target.checked

    if (!checked) this.sstRef.checked = false;
    this.setState({
      commissionChargeable: checked,
      ...(!checked && {
        sstChargeable: false
      })
    }, () => {
      this.updateCalculation();
    })
  }

  handleSST = value => {
    this.setState({sstChargeable: value.target.checked}, () => {
      this.updateCalculation()
    })
  }

  updateCalculation = () => {
    const {commissionRate, itemPrice, commissionChargeable, sstChargeable, form_data} = this.state

    let commission = commissionChargeable ? itemPrice * commissionRate / 100 : 0
    let sst = sstChargeable ? commission * 0.08 : 0

    this.setState({
      commission,
      sst,
      form_data: {
        ...form_data,
        commission,
        sst
      }
    })
  }

  handleDeliveryType = (e) => {
    let delivery_type = e.target.value;

    this.setState({
      delivery_type,
    })
  }

  submit = async e => {
    e.preventDefault();
    this.setState({submitting: true})

    const { form_data, delivery_type, mode } = this.state
    const { handleSaveManualOrder } = this.props

    let { payment_due_date, _id } = form_data

    let delivery_date, ship_by_date = '';

    if (delivery_type === 'nationwide') {
      ship_by_date = form_data.delivery_date.format('DD/MM/YYYY')
    } else if (delivery_type === 'on-demand') {
      delivery_date = form_data.delivery_date.format('DD/MM/YYYY')
    }

    if(payment_due_date){
      payment_due_date = payment_due_date.format('DD/MM/YYYY')
    }

    let url = mode === 'edit' ? `/manual_orders/${_id}/update` : '/manual_orders'

    let result = await api(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        ...form_data,
        delivery_date,
        ship_by_date,
        payment_due_date
      })
    })

    if(mode === 'edit'){
      handleSaveManualOrder()
      return
    }

    if(result.success){
      this.props.onSubmit(result);
      this.setState({submitting: false})
    } else {
      const {errors} = result;

      let error = [];
      for (e in errors){
        error.push(
          {msg: errors[e].message}
        )
      }

      this.setState({error, submitting: false});
    }
  }

  handleSelectDueDate = date => {
    const {form_data} = this.state
    this.setState({
      form_data: {
        ...form_data,
        payment_due_date: date
      },
      paymentDueDate: date
    })
  }

  handleSelectDate = date => {
    const {form_data} = this.state
    this.setState({
      form_data: {
        ...form_data,
        delivery_date: date
      },
      deliveryDate: date
    })
  }

  updateForm = e => {
    const {form_data} = this.state
    const node = e.target

    let update = {};

    if (node.type === 'checkbox') {
      update = {
        [node.name]: node.checked
      };
    } else {
      update = {
        [node.name]: node.value
      };
    }

    this.setState({form_data: {
      ...form_data,
      ...update,
    }})

  }

  updateGiftcard = e => {
    const {form_data} = this.state
    const node = e.target

    let valueStr = node.value

    let skuValue = _.split(valueStr, "-")

    this.setState({form_data: {
      ...form_data,
      giftcard_sku: skuValue[0],
      giftcard_value: skuValue[1],
      giftcard_product_id: skuValue[2],
      item_details: skuValue[3],
    }})

  }

  addFile = e => {
    const { form_data } = this.state;
    const file = e.target.files[0];

    if(file){
      let formData = new FormData();
      formData.append('file', file);
      const data = api('/file/upload/manual_order_file', {
        method: 'POST',
        body: formData
      }, {'Accept': 'application/json'})
        .then(data => {
          const url = data.file_short_url

          this.setState({
            form_data: {
              ...form_data,
              order_file: url
            },
            file_url: url,
            orderFile: file
          })
        })
    }
  }

  uploadInvoice = e => {
    const { form_data } = this.state;
    const { vendor } = form_data;
    const file = e.target.files[0];

    if(file){
      this.setState({
        invoice_uploading: true,
      })
      let formData = new FormData();
      formData.append('file', file);
      if(vendor){
        formData.append('vendor', vendor)
      }

      const data = api('/file/upload/manual_order_invoice', {
        method: 'POST',
        body: formData
      }, {'Accept': 'application/json'})
        .then(data => {
          const url = data.file_short_url

          this.setState({
            form_data: {
              ...form_data,
              order_invoice: url
            },
            invoiceFile: file,
            invoice_uploading: false,
          })
        })
    }
  }

  uploadSalesReceipts = files => {
    const { form_data } = this.state;
    const { sales_receipt } = form_data

    if(files && files.length > 0){
      this.setState({
        sales_receipt_uploading: true
      });

      let result = [...(sales_receipt || [])]

      return Promise.map(files, function (file) {
        var formData = new FormData();
        formData.append('file', file);

        return api('/file/upload/manual_order_sales_receipt', {
          method: 'POST',
          body: formData
        }, {'Accept': 'application/json'})
          .then(data => {
            const url = data.file_short_url

            result.push({
              filename: file.name,
              link: url
            })
          })
      }, {
        concurrency: 1
      })
        .then(() => {
          this.setState({
            form_data: {
              ...form_data,
              sales_receipt: result
            },
            sales_receipt_uploading: false
          })
        })
    }
  }

  clearSalesReceipt = () => {
    const { form_data } = this.state;

    this.setState({
      form_data: {
        ...form_data,
        sales_receipt: []
      },
    })
  }


  render() {
    const { merchantsOptions } = this.props
    const { mode, commissionChargeable, commissionRate, partialPaid, itemPrice, markupType, markup, markupTotal, shippingFee, vendorSelected, commission, sst, deliveryDate, file_url, error, orderFile, invoiceFile, invoice_uploading, sales_receipt_uploading, form_data, paymentDueDate, giftcard_skus, delivery_type, submitting } = this.state

    return(
      <div>
        { 
          merchantsOptions && merchantsOptions.length &&
          <Loader/>
        }
        <Modal className="manual-order-modal" show={true} onHide={this.handleOnClose} size="xl">
          <Modal.Header className="mx-2 mt-1" style={{borderBottom: 'none'}} closeButton><h4 className="mb-0">New Manual Order</h4></Modal.Header>
          <Modal.Body className="mx-4 px-0 py-4" style={{borderTop: '1px solid #dee2e6'}}>
              {
                error &&
                <Alert variant="danger">
                  {
                    Array.isArray(error) ?
                    <ul>
                    {
                      error.map((err, i) => (
                        <li key={i}>
                          {err.msg}
                        </li>
                      ))
                    }
                    </ul>
                    :
                    error
                  }
                </Alert>
              }
            <form onSubmit={this.submit}>
              <Row>
                <Col sm={6}>
                  <Card className="tealCard shadow-sm mb-3">
                    <Card.Header className="tealCardHeader">Customer Info</Card.Header>
                    <Card.Body className="d-grid gap-3 pb-4">
                      <div className=" d-grid gap-1">
                        <label htmlFor="first_name">First Name *</label>
                        <input className="form-control" name="first_name" value={form_data.first_name} type="text" placeholder="First Name" onChange={this.updateForm} required />
                      </div>
                      <div className=" d-grid gap-1">
                        <label htmlFor="last_name">Last Name</label>
                        <input className="form-control" name="last_name" value={form_data.last_name} type="text" placeholder="Last Name" onChange={this.updateForm} />
                      </div>
                      <div className=" d-grid gap-1">
                        <label htmlFor="email">Email *</label>
                        <input className="form-control" name="email" value={form_data.email} type="email" placeholder="Email" onChange={this.updateForm} required/>
                      </div>
                      <div className="form-check gap-1">
                        <input type="checkbox" className="form-check-input" checked={form_data.notify_customer} name="notify_customer" id="notify_customer" onChange={this.updateForm} />
                        <label className="form-check-label" htmlFor="notify_customer">Notify Customer</label>
                      </div>
                    </Card.Body>
                  </Card>
                  <Card className="tealCard shadow-sm mb-3">
                    <Card.Header className="tealCardHeader">Delivery Info</Card.Header>
                    <Card.Body className="d-grid gap-3 pb-4">
                    <div className=' d-grid gap-1'>
                      <label htmlFor='sender_phone_number'>Sender's Phone Number</label>
                      <input className="form-control" name="sender_phone_number" value={form_data.sender_phone_number} type="text" placeholder="Sender's Phone Number" onChange={this.updateForm} />
                    </div>
                    <div className=' d-grid gap-1'>
                      <label htmlFor='receiver'>Receiver</label>
                      <input className="form-control" name="receiver" value={form_data.receiver} type="text" placeholder="Receiver" onChange={this.updateForm} />
                    </div>
                    <div className=' d-grid gap-1'>
                      <label htmlFor='delivery_address'>Delivery Address *</label>
                      <textarea className="form-control" name="delivery_address" value={form_data.delivery_address} placeholder="Delivery Address" onChange={this.updateForm} required />
                    </div>
                    <div className=' d-grid gap-1'>
                      <label htmlFor='contact'>Contact *</label>
                      <input className="form-control" name="contact" value={form_data.contact} type="text" placeholder="Contact" onChange={this.updateForm} required />
                    </div>
                    <div className=' d-grid gap-1'>
                      <label htmlFor='contact'>Seller Remarks</label>
                      <textarea className="form-control" name="remarks" value={form_data.remarks} placeholder="Seller Remarks" onChange={this.updateForm} />
                    </div>
                    </Card.Body>
                  </Card>
                  <Card className="tealCard shadow-sm mb-3">
                    <Card.Header className="tealCardHeader">Billing Address</Card.Header>
                    <Card.Body className="d-grid gap-3 pb-4">
                    <div className=" d-grid gap-1">
                      <label htmlFor="first_name_billing">First Name</label>
                      <input className="form-control" name="first_name_billing" value={form_data.first_name_billing} type="text" placeholder="First Name" onChange={this.updateForm} />
                    </div>
                    <div className=" d-grid gap-1">
                      <label htmlFor="last_name_billing">Last Name</label>
                      <input className="form-control" name="last_name_billing" value={form_data.last_name_billing} type="text" placeholder="Last Name" onChange={this.updateForm} />
                    </div>
                    <div className=" d-grid gap-1">
                      <label htmlFor="address_billing">Address</label>
                      <textarea className="form-control" name="address_billing" value={form_data.address_billing} placeholder='Address' onChange={this.updateForm} />
                    </div>
                    <div className=" d-grid gap-1">
                      <label htmlFor="phone_number_billing">Phone Number</label>
                      <input className="form-control" name="phone_number_billing" value={form_data.phone_number_billing} type="text" placeholder="Phone Number" onChange={this.updateForm} />
                    </div>
                    </Card.Body>
                  </Card>
                </Col>
                <Col sm={6}>
                <Card className="tealCard shadow-sm mb-3">
                  <Card.Header className="tealCardHeader">Items</Card.Header>
                    <Card.Body className="d-grid gap-3 pb-4">
                    <div className=" d-grid gap-1">
                      <label htmlFor="order_id">Order ID *</label>
                      <input className="form-control" name="order_id" value={form_data.order_id} type="text" placeholder="Order ID" onChange={this.updateForm} required/>
                    </div>
                    <div className=" d-grid gap-1">
                      <label htmlFor="vendor">Vendor *</label>
                      <select className="form-control" name="vendor" value={form_data.vendor} onChange={this.getVendor} required >
                        <option value="">Select Vendor</option>
                        {
                          merchantsOptions.map(option => (
                            <option key={option.key} value={option.value}>{option.name}</option>
                          ))
                        }
                      </select>
                    </div>
                    <div className="form-check gap-1">
                      <input type="checkbox" className="form-check-input" checked={form_data.is_giftcard} name="is_giftcard" id="is_giftcard" onChange={this.getVoucherSKUs} />
                      <label className="form-check-label" htmlFor="is_giftcard">e-gift card</label>
                    </div>
                    {
                      form_data.is_giftcard &&
                      <div className="d-grid gap-3">
                        <div className="d-grid gap-1">
                          <label htmlFor="giftcard_sku">SKU*</label>
                          <select className="form-control" name="giftcard_sku" value={form_data.giftcard_sku} onChange={this.updateGiftcard} required >
                            <option value="" selected disabled>Select SKU</option>
                            {
                              giftcard_skus &&
                              giftcard_skus.map(option => (
                                <option key={option.sku} value={`${option.sku}-${option.value}-${option.product_id}-${option.name}`}>{option.sku} - RM{option.value}</option>
                              ))
                            }
                          </select>
                        </div>
                        <div className="d-grid gap-1">
                          <label htmlFor="item_details">Item Details</label>
                          <input className="form-control" type="text" disabled value={form_data.item_details}/>
                        </div>
                        <div className="d-grid gap-1">
                          <label htmlFor="giftcard_code">Code*</label>
                          <textarea className="form-control" name="giftcard_code" value={form_data.giftcard_code} placeholder="Code" onChange={this.updateForm} />
                        </div>
                        <div className=" d-grid gap-1">
                          <label htmlFor="vendor">Gift Card Type*</label>
                          <select className="form-control" name="giftcard_type" value={form_data.giftcard_type} onChange={this.updateForm} required >
                            <option disabled selected value="">Select Gift Card Type</option>
                            <option value="dynamic">Dynamic</option>
                            <option value="dynamic" disabled>Fixed</option>
                          </select>
                        </div>
                        <div className=" d-grid gap-1">
                          <label htmlFor="vendor">Gift Card Redemption Type*</label>
                          <select className="form-control" name="redemption_type" value={form_data.redemption_type} onChange={this.updateForm} required >
                            <option disabled selected value="">Select Gift Card Redemption Type</option>
                            <option value="pre">Pre</option>
                            <option value="post" >Post</option>
                          </select>
                        </div>
                        <div className="d-grid gap-1">
                          <label htmlFor="code">Validity (Days)*</label>
                          <input type="number" className="form-control" name="validity" value={form_data.validity} placeholder="365" onChange={this.updateForm} required />
                        </div>
                      </div>
                    }
                    {
                      !form_data.is_giftcard &&
                      <div className="d-grid gap-1">
                        <label htmlFor="item_details">Item Details</label>
                        <textarea className="form-control" name="item_details" value={form_data.item_details} placeholder="Item Details" onChange={this.updateForm} />
                      </div>
                    }
                    <div className="d-grid gap-1">
                      <label htmlFor="order_file">Order File</label>
                      <Row>
                      <Col xs={5}>
                        <label className="custom-file-upload" style={{width:'100%'}}>
                          <FontAwesomeIcon icon={solid('folder-open')} className="me-2"/>Choose File
                          <input type="file" name="order_file" onChange={this.addFile}/>
                        </label>
                      </Col>
                      <Col xs={7} className="d-grid p-0" style={{fontSize: '0.9em', color: '#5A5A5A'}}>
                      {
                        orderFile &&
                        <span>{orderFile.name}</span>
                      }
                      {
                        file_url &&
                        <span>{file_url}</span>
                      }
                      </Col>
                      </Row>
                    </div>

                    <div className=" d-grid gap-1">
                      <label htmlFor="delivery_type">Delivery Type</label>
                      <select className="form-control" name="delivery_type" onChange={this.handleDeliveryType} required value={delivery_type} >
                        <option value="nationwide">Nationwide</option>
                        <option value="on-demand">On-Demand</option>
                      </select>
                    </div>

                    {
                      !form_data.is_giftcard &&
                      <div className=' d-grid gap-1'>
                        <label htmlFor='delivery_date'>Ship By / Delivery Date *</label>
                        <SingleDatePicker
                          required
                          date={deliveryDate}
                          onDateChange={this.handleSelectDate}
                          displayFormat="DD-MM-YYYY"
                          numberOfMonths={1}
                          showClearDate={true}
                          showDefaultInputIcon={true}
                          focused={this.state.deliveryDateFocused}
                          onFocusChange={({ focused: deliveryDateFocused }) => this.setState({ deliveryDateFocused })}
                        />
                      </div>
                    }
                    <div className=' d-grid gap-1'>
                      <label htmlFor='message_on_card'>Message on Card</label>
                      <textarea className="form-control" name="message_on_card" value={form_data.message_on_card} placeholder="Message on Card" onChange={this.updateForm} />
                    </div>
                    <div className=' d-grid gap-1'>
                      <label htmlFor='item_price'>Item Price *</label>
                      <input className="form-control" name="item_price" value={form_data.item_price} type="text" placeholder={`Item Price (${currency})`} onChange={this.handleItemPrice} required/>
                    </div>
                    <div className=' d-grid gap-1'>
                      <label htmlFor='item_price'>Markup Type</label>
                      <Form.Select className="form-control" name="markup_type" value={form_data.markup_type} onChange={this.handleMarkupType} required defaultValue="fixed">
                        <option value="fixed">Fixed</option>
                        <option value="percentage">Percentage</option>
                      </Form.Select>
                    </div>
                    <div className=' d-grid gap-1'>
                      <label htmlFor='item_price'>Markup</label>
                      <input className="form-control" name="markup" value={form_data.markup} type="number" step=".01" onChange={this.handleMarkup} />
                    </div>
                    {
                      !form_data.is_giftcard &&
                      <div className=' d-grid gap-1'>
                        <label htmlFor='shipping_fee'>Shipping Fee *</label>
                        <input className="form-control" name="shipping_fee" value={form_data.shipping_fee} type="text" placeholder={`Shipping Fee (${currency})`} onChange={this.handleShippingFee} required/>
                      </div>
                    }
                    <div style={{fontSize:'0.9em'}}>
                      <input ref={input => this.commissionRef = input} disabled={!vendorSelected} onChange={this.handleCommission} name="commission_chargeable" type="checkbox" style={{marginRight: "5px"}} />
                      <label htmlFor='commission_chargeable'>{`Commission  Chargeable (${commissionRate || "-"}%)`} </label>
                    </div>

                    <div style={{fontSize:'0.9em'}}>
                      <input ref={input => this.sstRef = input} disabled={!commissionChargeable} name="sst" type="checkbox" onChange={this.handleSST} style={{marginRight: "5px"}} />
                      <label htmlFor='sst'>SST</label>
                    </div>
                    </Card.Body>
                  </Card>
                <Card className="tealCard shadow-sm mb-3">
                  <Card.Header className="tealCardHeader">Order Info</Card.Header>
                  <Card.Body className="d-grid gap-2 pb-4">
                    <div>
                      <Row>
                        <Col xs={6} className="text-end">Financial Status*</Col>
                        <Col xs={6}>
                          <Form.Select className='form-control' value={form_data.financial_status} name='financial_status' onChange={this.updateForm} required>
                            <option value="">-</option>
                            <option value="paid">Paid</option>
                            <option value="partially_paid">Partially Paid</option>
                            <option value="unpaid">Unpaid</option>
                          </Form.Select>
                        </Col>
                      </Row>
                    </div>

                    {
                      form_data.financial_status === 'partially_paid' &&
                        <>
                          <Row>
                            <Col xs={6} className="text-end">Partial Paid Amount*</Col>
                            <Col xs={6}>
                              <input className="form-control" name="partial_paid_amount" value={form_data.partial_paid_amount} type="number" step=".01" placeholder={`Partial Paid Amount (${currency})`} onChange={this.handlePartialPaid} required/>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg={6} className="text-end">Due Date*</Col>
                            <Col lg={6}>
                              <SingleDatePicker
                                date={paymentDueDate}
                                onDateChange={this.handleSelectDueDate}
                                displayFormat="DD-MM-YYYY"
                                numberOfMonths={1}
                                showClearDate
                                required
                                block
                                small
                                focused={this.state.paymentDueDateFocused}
                                onFocusChange={({ focused: paymentDueDateFocused }) => this.setState({ paymentDueDateFocused })}
                              />
                            </Col>
                          </Row>
                        </>
                    }

                      <div>
                        <Row>
                          <Col xs={6} className="text-end">Item Price</Col>
                          <Col xs={6}>{currency} {itemPrice.toFixed(2)}</Col>
                        </Row>

                        { markupType && markup && markupTotal &&
                        <>
                          <Row>
                            <Col xs={6} className="text-end">Markup</Col>
                            <Col xs={6}>{markupType === 'percentage' ? `${markup}%` : markupType}</Col>
                          </Row>

                          <Row>
                            <Col xs={6} className="text-end">Markup Total</Col>
                            <Col xs={6}>{currency} {markupTotal.toFixed(2)}</Col>
                          </Row>
                        </>
                        }

                        <Row>
                          <Col xs={6} className="text-end">Total Item Price</Col>
                          <Col xs={6}>{currency} {(itemPrice + markupTotal).toFixed(2)}</Col>
                        </Row>

                        <hr style={{margin: '5px', color:'#707070'}} />

                        <Row>
                          <Col xs={6} className="text-end">Subtotal</Col>
                          <Col xs={6}>{currency} {itemPrice.toFixed(2)}</Col>
                        </Row>

                        <Row>
                          <Col xs={6} className="text-end">Shipping Subtotal</Col>
                          <Col xs={6}>{currency} {shippingFee.toFixed(2)}</Col>
                        </Row>

                        <Row>
                          <Col xs={6} className="text-end">Commission</Col>
                          <Col xs={6}>{currency} {commission.toFixed(2)}</Col>
                        </Row>

                        <Row>
                          <Col xs={6} className="text-end">SST</Col>
                          <Col xs={6}>{currency} {sst.toFixed(2)}</Col>
                        </Row>

                        <Row>
                          <Col xs={6} className="text-end">Balance</Col>
                          <Col xs={6}>{currency} {(itemPrice - commission - sst + shippingFee).toFixed(2)}</Col>
                        </Row>

                        <hr style={{margin: '5px', color:'#707070'}} />

                        <Row>
                          <Col xs={6} className="text-end">Total</Col>
                          <Col xs={6}>{currency} {(itemPrice + shippingFee + markupTotal).toFixed(2)}</Col>
                        </Row>

                        {
                          partialPaid > 0 &&
                            <Row>
                              <Col xs={6} className="text-end">Total Due</Col>
                              <Col xs={6}>{currency} {(itemPrice + shippingFee - partialPaid).toFixed(2)}</Col>
                            </Row>
                        }
                      </div>

                        <Row>
                          <Col xs={6} className="text-end"><label htmlFor="order_invoice">Order Invoice</label></Col>
                          <Col xs={6}>
                            <label className="custom-file-upload" style={{width:'100%'}}>
                              <FontAwesomeIcon icon={solid('folder-open')} className="me-2"/>Choose File
                              <input type="file" name="order_invoice" onChange={this.uploadInvoice}/>
                            </label>
                            <div className="d-grid" style={{fontSize: '0.9em', color: '#5A5A5A'}}>
                              {
                                invoice_uploading &&
                                  <Loader />
                              }
                              {
                                invoiceFile &&
                                  <span>{invoiceFile.name}</span>
                              }
                              {
                                form_data.order_invoice &&
                                  <Anchor target="_blank" href={form_data.order_invoice} variant="primary" style={{wordBreak: 'break-all'}}>{form_data.order_invoice}</Anchor>
                              }
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={6} className="text-end">
                            <label htmlFor="sales_receipt">Sales Receipt</label>
                            {
                              form_data.sales_receipt && form_data.sales_receipt.length > 0 &&
                                <Button onClick={this.clearSalesReceipt} size='sm' variant="secondary">Clear</Button>
                            }
                          </Col>
                          <Col xs={6}>
                            <Dropzone
                              className="dropzone"
                              style={{}}
                              onDrop={this.uploadSalesReceipts}
                              multiple={true}
                            >
                              <Button className="custom-file-upload">
                                <FontAwesomeIcon icon={solid('folder-open')} className="me-2"/>Choose File
                              </Button>
                            </Dropzone>
                            <div className="d-grid" style={{fontSize: '0.9em', color: '#5A5A5A'}}>
                              {
                                sales_receipt_uploading &&
                                  <Loader />
                              }
                              {
                                form_data.sales_receipt && form_data.sales_receipt.map((receipt, i) => {
                                  return (
                                    <div key={i} className="d-grid">
                                      <span>{receipt.filename}</span>
                                      <Anchor target="_blank" href={receipt.link} variant="primary" style={{wordBreak: 'break-all'}}>{receipt.link}</Anchor>
                                    </div>
                                  )
                                })
                              }
                            </div>
                          </Col>
                          </Row>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
              <Row className="mx-auto">
                <Button className="shadow-sm btn-br-6" variant="primary" type="submit" disabled={submitting}>{mode === 'edit' ? 'EDIT' : 'SUBMIT'}</Button>
              </Row>
            </form>
          </Modal.Body>
        </Modal>
      </div>
    )
  }
}

export default connect((state, props) => {
  const { match } = props;
  return {
    merchantsOptions: (state.merchants && state.merchants.items) ? state.merchants.items.map(merchant => {
      return {
        key: merchant,
        value: merchant,
        name: merchant
      }
    }) : []
  };
}, {
  ...merchantsActionCreators
})(CreateManualOrder);
